@use "../config/" as *;
@include hamburger;

.site-header {
    width: calc(100% - calc(var(--header-space)*2));
    z-index: var(--z-header);
    border-radius: 3rem;
    transition: .5s ease-in-out;
    @include position(var(--header-space),null,null,var(--header-space),fixed);
    // @extend %transition;
    @extend %pure-white-900-bg;
    &:has(.btn-hamburger[aria-expanded="true"]) {
        top: 0;
        left: 0;
        border-radius: 0;
        @extend %w-100;
        .primary-header {
            border-radius: 0;
            border-bottom: .1rem solid var(--neutral-400);
        }
        .waf-key-events-sticky {
            opacity: 0;
            pointer-events: none;
        }
    }
    &:before {
        content: '';
        height: var(--header-before-height);
        width: calc(100% + 3rem);
        border-radius: 0 0 3rem 3rem;
        @include position(var(--space-3-neg),null,null,var(--space-3-neg));
        @extend %main_tlbr;
    }
}
.primary-header {
    height: var(--header-height);
    padding-block: .8rem;
    z-index: var(--z-default);
    border-radius: 3rem;
    transition: .5s ease-in-out;
    // @extend %transition;
    @extend %relative;
    @extend %pure-white-900-bg;
    @extend %px-3;
    &-wrap {
        @extend %flex-n-c;
    }
    &:has(.btn-search[aria-expanded="true"]) {
        border-radius: 3rem 3rem 0 0;
    }
}
.site-logo {
    margin-right: auto;
    .logo {
        @extend %relative;
        &::before {
            content: '';
            width: var(--reskin-logo-width);
            height: var(--reskin-logo-height);
            @include bgImg("ecn-logo.svg", center, contain, no-repeat);
            @extend %d-block;
        }
        &-text {
            opacity: 0;
            pointer-events: none;
            visibility: hidden;
            @extend %absolute;
        }
    }
}
.site-search {
    .btn-search {
        .btn-text {
            &::before {
                @include icon(search, 16);
                @extend %men-deep_blue;
            }
        }
        &[aria-expanded="true"] {
            & ~ .search-input-wrap {
                @include dropdown(open);
            }
            .btn-text {
                &::before {
                    @include icon(close,16);
                }
            }
        }
    }
    .search {
        &-input {
            border: 0;
            @extend %font-16-pm;
            @extend %w-100;
            @extend %p-2-7;
            @extend %transparent-bg;
            @extend %pure-white-900;
            &-wrap {
                z-index: var(--z-default);
                border-radius: 0 0 3rem 3rem;
                @include position(var(--header-height),null,null,0);
                @include dropdown(close);
                @extend %men_dark_tb;
                @extend %w-100;
            }
            &::placeholder {
                @extend %pure-white-900;
            }
        }
        &-label {
            position: absolute;
            inset: 0;
            pointer-events: none;
            @extend %flex-n-c;
            @extend %font-0;
            @extend %px-3;
            @extend %font-14;
            &::before {
                @include icon(search,12);
                @extend %pure-white-900;
            }
        }
    }
}
.btn-hamburger {
    width: 9.5rem;
    @include flex-config(flex, row-reverse, null, center);
    @extend %font-20-pm;
    &::after {
        content: "MENU";
        @extend %pt-1;
        @extend %men-deep_blue;
    }
    &[aria-expanded="true"] {
        &::after {
            content: "CLOSE";
        }
        & ~ .site-nav {
            transform: translateX(0);
            pointer-events: initial;
            opacity: 1;
        }
    }
}
.site-nav {
    height: var(--window-inner-height);
    pointer-events: none;
    opacity: 0;
    transform: translateX(100%);
    transition: .5s ease-in-out;
    @include position(var(--header-height),null,null,0,fixed);
    @extend %w-100;
    // @extend %transition;
    @extend %pure-white-900-bg;
    &-wrapper {
        @include flex-config(flex,row-reverse);
    }
    &-left {
        width: calc(var(--window-inner-width) - 13rem);
        flex-shrink: 0;
        @extend %flex-column-sb-n;
        @extend %p-3;
    }
    &-right {
        width: 13rem;
        flex-shrink: 0;
        @extend %neutral-10-bg;
    }
    .nav {
        &-link-list {
            list-style: none;
            height: calc(100dvh - var(--official-Partner-module) - var(--social-height) - var(--header-height) - 5rem);
            overflow-y: auto;
            @extend %pl-0;
        }
        &-link {
            @extend %relative;
            &:before {
                width: .4rem;
                left: 0;
                @extend %pos-y-center;
                @extend %h-100;
                @extend %men-deep_blue-bg;
            }
            &:not(:last-child)::after {
                content: "";
                height: 0.1rem;
                @extend %w-100;
                @extend %neutral-10-bg;
                @extend %pos-bl;
            }
            &.active {
                .nav-anchor {
                    @extend %men-blue_light;
                }
                &:before {
                    @extend %men-blue_light-bg;
                }
            }
        }
        &-anchor {
            height: 3.7rem;
            @extend %men-deep_blue;
            @extend %flex-n-c;
            @extend %w-100;
            @extend %relative;
            // &:before {
            //     width: .4rem;
            //     left: 0;
            //     @extend %pos-y-center;
            //     @extend %h-100;
            //     @extend %men-deep_blue-bg;
            // }
        }
        &-text {
            color: inherit;
            @extend %relative;
            @extend %font-18-pm;
            @extend %uppercase;
            &:before {
                width: .4rem;
                left: -1rem;
                top: calc(50% - .3rem);
                @extend %pos-y-center;
                @extend %h-100;
                @extend %men-deep_blue-bg;
            }
        }
    }
    .sub-ul {
        list-style: none;
        @extend %px-0;
        .sub-nav-anchor {
            height: 0;
            @extend %men-deep_blue;
            @extend %flex-n-c;
            @extend %w-100;
            @extend %transition;
        }
        .sub-nav-link {
            @extend %transition;
            @extend %hidden;
        }
        .sub-nav-text {
            color: inherit;
            @extend %font-16-pm;
            @extend %uppercase;
        }
    }
    button.nav-anchor {
        justify-content: space-between;
        &[aria-expanded="true"] {
            & ~ .sub-ul {
                .sub-nav-anchor {
                    height: 3.5rem;
                }
            }
            &::after {
                transform: rotate(180deg);
                transform-origin: center;
            }
        }
        &::after {
            @include icon(chevron-down,10);
            @extend %transition;
        }
    }
    .social-navigation,.official-Partner-module {
        @extend %d-block;
    }
}
.official-Partner-module {
    margin-block: auto var(--space-3);
    @extend %d-none;
    .event-countdown {
        height: var(--official-Partner-module);
        @extend %mb-0;
        @extend %p-0;
    }
    .swiper-button {
        &-next,&-prev {
            @extend %d-none;
        }
    }
    .waf-head {
        height: 5rem;
        border-radius: var(--half-radius) var(--half-radius) 0 0;
        z-index: var(--z-default);
        border-bottom: .1rem solid var(--pure-white-900);
        @extend %men-deep_blue-bg;
        @extend %flex-c-c;
        .img-box {
            width: 8.4rem;
            height: 2.5rem;
            background: transparent;
            img {
                object-fit: contain;
            }
        }
    }
    .card {
        &-thumbnail {
            flex-wrap: wrap;
            @extend %pb-3;
            @extend %flex-c-n;
            .img-box {
                width: 12rem;
                height: 4.5rem;
                background: transparent;
                img {
                    object-fit: contain;
                }
            }
        }
        &-title {
            padding-block: 1.2rem;
            margin-bottom: 1.2rem;
            border-bottom: .1rem solid var(--neutral-400);
            @extend %w-100;
            @extend %px-3;
            @extend %text-center;
            @extend %font-12-sb;
            @extend %pure-white-900;
            @extend %uppercase;
        }
        &-item {
            height: 17rem;
            border-radius: 0 0 var(--half-radius) var(--half-radius);
            @extend %px-3;
            @extend %pb-3;
            @extend %men-deep_blue-bg;
        }
    }
    .countdown {
        &-list {
            @extend %gap-2;
            @extend %flex-sb-n;
        }
        &-label {
            @extend %font-10-pm;
        }
        &-count {
            @extend %font-12-tsb;
        }
        &-count,&-label {
            @extend %pure-white-900;
        }
        &-item {
            flex: 1;
            min-width: 3.5rem;
            height: 3.3rem;
            @extend %relative;
            @extend %men-blue-bg;
            @extend %half-radius;
            @extend %flex-column-c-c;
            &:not(:last-child) {
                &:after {
                    content: ':';
                    right: -.7rem;
                    @extend %pure-white-900;
                    @extend %pos-y-center;
                }
            }
        }
    }
}
.social-navigation {
    height: var(--social-height);
    @extend %d-none;
    @extend %relative;
    &::before{
        content: '';
        height: .1rem;
        width: calc(100% + var(--space-6));
        @extend %neutral-300-bg;
        @include position(0,null,null,var(--space-3-neg));
    }
    .social {
        &-label {
            @extend %mb-2;
            @extend %uppercase;
            @extend %flex;
            @extend %men-deep_blue;
            @extend %font-10-sb;
        }
        &-list {
            list-style: none;
            @extend %flex;
            @extend %pl-0;
            &-wrap {
                @extend %py-3;
            }
        }
        &-link {
            flex: 1;
            @extend %flex-c-c;
            @extend %men-deep_blue;
            @each $key, $val in $social {
                &-#{$key} {
                    .social-anchor::before {
                        @include icon($key,16);
                    }
                }
            }
            &-soundcloud {
                .social-anchor::before {
                    content: '\e991';
                    font-size: 1.8rem;
                }
            }
            &-telegram {
                .social-anchor::before {
                    content: '\e990';
                    font-size: 1.8rem;
                }
            }
        }
        &-anchor {
            @extend %men-deep_blue;
            @extend %w-100;
            @extend %h-100;
            @extend %flex-c-c;
        }
        &-text {
            @extend %font-0;
        }
    }
}
.secondary-header {
    position: relative;
    top: 4.2rem;
    height: 2.5rem;
    transition: .5s ease-in-out;
    // @extend %transition;
    @extend %w-100;
    &-wrapper {
        @extend %gap-2;
        @extend %flex-n-c;
        @extend %h-100;
    }
    .secondary {
        &-series-name {
            width: 40%;
            flex-shrink: 0;
        }
        &-series-text {
            @include truncate-text(1);
            @extend %pt-1;
            @extend %uppercase;
            @extend %font-14-pm;
            @extend %pure-white-900;
        }
        &-navigation {
            width: 60%;
        }
        &-nav {
            &-list {
                list-style: none;
                overflow-x: auto;
                @extend %pl-0;
                @extend %flex;
            }
            &-text {
                @extend %pt-1;
                @extend %uppercase;
                @extend %font-14-pm;
                @extend %pure-white-900;
            }
            &-link {
                @extend %relative;
                &:not(:last-child) {
                    &:after {
                        content: '';
                        right: 0;
                        width: .1rem;
                        @extend %neutral-10-bg;    
                        @extend %h-100;
                        @extend %pos-y-center;
                    }
                }
                &.active {
                    .secondary-nav-text {
                        @extend %men-turquoise;
                    }
                }
            }
            &-anchor {
                height: 2.5rem;
                padding-inline: .8rem;
                @extend %flex-c-c;
            }
        }
    }
}
.event-site-section {
    overflow-y: scroll;
    height: calc(var(--window-inner-height) - var(--header-height));
    @extend %p-3;
    .event-site {
        &-label {
            align-items: center;
            @extend %pt-1;
            @extend %mb-6;
            @extend %pl-2;
            @extend %relative;
            @extend %uppercase;
            @extend %men-deep_blue;
            @extend %font-30-pm;
            @extend %d-none;
            &:before {
                content: '';
                width: .4rem;
                left: 0;
                @extend %pos-y-center;
                @extend %h-100;
                @extend %men-deep_blue-bg;
            }
        }
        &-list {
            list-style: none;
            @extend %pl-0;
        }
        &-item {
            &:not(:last-child) {
                border-bottom: .1rem solid var(--neutral-300);
                @extend %pb-3;
                @extend %mb-3;
            }
        }
    }
}
.federation-navigation {
    margin-inline: var(--space-8-neg);
    padding: var(--space-8);
    @extend %d-none;
    @extend %neutral-10-bg;
    .federation-site-label {
        border-bottom: .1rem solid var(--neutral-200);
        @extend %mb-3;
        @extend %py-3;
        @extend %flex;
        @extend %uppercase;
        @extend %font-30-pm;
        @extend %men-deep_blue;
    }
    .federation-nav {
        &-list {
            list-style: none;
            flex-wrap: wrap;
            @extend %gap-3;
            @extend %flex;
            @extend %pl-0;
        }
        &-link {
            min-width: 12rem;
        }
        &-text {
            @extend %font-10-sb;
            @extend %uppercase;
            @extend %neutral-600;
        }
        &-anchor {
            padding-left: 0;
            @extend %gap-1;
            @extend %flex-n-c;
            @extend %relative;
            &:after {
                @extend %neutral-600;
                @include icon(top-right,10);
            }
        }
    }
}
.quicklink-section {
    z-index: var(--z-quicklink-section);
    @extend %pure-white-900-bg;
    @extend %w-100;
    @include position-combo(bl, null, null, fixed);
    .quicklink {
        &-list {
            height: 6rem;
            overflow-x: auto;
            @extend %flex-n-c;
        }
        &-item {
            @extend %gap-1;
            @extend %h-100;
            @extend %relative;
            @extend %px-4;
            @extend %flex-sb-c;
            &::before {
                width: 4.5rem;
                height: 4.5rem;
                @extend %men-deep_blue;
                @extend %transition;
                @extend %neutral-10-bg;
                @extend %flex-c-c;
                @extend %circle-radius;
            }
            &:not(:first-child) {
                &::after {
                    content: '';
                    left: 0;
                    width: .1rem;
                    @extend %h-50;
                    @extend %neutral-500-bg;
                    @extend %pos-y-center;
                }
            }
            &-scores::before {
                @include icon(helmet,20);
            }
            &-stats::before {
                @include icon(stats,20);
            }
            &-about::before {
                @include icon(information-sign,20);
            }
            &-series::before {
                @include icon(web,20);
            }
            &-calendar::before {
                @include icon(icon-fixtures,16);
            }
        }
        &-text {
            white-space: nowrap;
            @extend %uppercase;
            @extend %men-deep_blue;
            @extend %font-14-pm;
        }
        &-accordion {
            [aria-expanded="true"] {
                bottom: 6rem;
                ~ .accordion-body {
                    bottom: 0;
                    height: 6rem;
                    overflow: visible;
                }
                &::after {
                    rotate: 270deg;
                }
            }
        }
    }
    .accordion {
        &-title {
            @extend %men-deep_blue;
            @extend %d-none;
            @extend %uppercase;
            @extend %font-20-pm;
        }
        &-body {
            position: absolute;
            height: 0;
            overflow: hidden;
            @extend %w-100;
            @extend %pure-white-900-bg;
            @extend %transition;
        }
        &-btn {
            position: absolute;
            bottom: 0;
            height: 4rem;
            @extend %transition;
            @extend %relative;
            @extend %px-3;
            @extend %flex-sb-c;
            @extend %men_dark_rl;
            @extend %w-100;
            &::before {
                content: "What are you looking for?";
                @extend %transition;
                @extend %font-10-sb;
                @extend %uppercase;
                @extend %pure-white-900;
            }
            &::after {
                rotate: 90deg;
                @extend %transition;
                @extend %pure-white-900;
                @include icon(double-chev-left, 12);
                animation: opennow 2s alternate infinite;
            }
            .btn-text {
                @extend %font-0;
            }
        }
    }
}
.sticky {
    .primary-header {
        border-radius: 0;
    }
    .site-header {
        top: 0;
        left: 0;
        border-radius: 0;
        @extend %w-100;
        &:before {
            content: unset;
        }
    }
    .waf-key-events-sticky {
        top: 2.7rem;
        left: 0;
        right: 0;
        border-radius: 0;
        @extend %w-100;
    }
    .site-search .search-input-wrap {
        border-radius: 0;
    }
    .secondary-header {
        top: 3.2rem;
        height: 4rem;
        @extend %main_tlbr;
        &-wrapper {
            @extend %px-2;
        }
    }
}
.event-page {
    .site-header {
        &::before {
            height: calc(var(--header-before-height) + 3rem);
        }
    }
}
@include mq(col-md) {
    .site-nav {
        &-left {
            width: calc(100% - 40%);
        }
        &-right {
            width: 40%;
        }
    }
    .event-site-section {
        .event-site {
            &-list {
                display: flex;
                flex-wrap: wrap;
            }
            &-item {
                min-width: 10rem;
                flex: 1;
            }
        } 
    }
}
@include mq(col-lg) {
    .site-header {
        top: var(--social-height);
        &:has(.btn-hamburger[aria-expanded="true"]) {
            top: var(--space-8);
            left: var(--header-space);
            width: calc(100% - calc(var(--header-space)*2));
            border-radius: 3rem;
            .primary-header {
                border-radius: 3rem;
                border-bottom: 0;
            }
        }
        &::before {
            top: var(--space-8-neg);
            height: var(--header-before-height);
        }
    }
    .primary-header {
        height: var(--header-height);
        padding: 0;
        &-wrap {
            height: 100%;
            box-shadow: 0 .4rem .4rem 0 clr(neutral-900,1);
            padding-inline: var(--space-2);
            border-radius: 3rem;
            @include flex-config(flex,null,center,center);
            &:has(.btn-search[aria-expanded="true"]) {
                box-shadow: unset;
                &::before {
                    box-shadow: unset;
                }
            }
            &::before {
                content: '';
                pointer-events: none;
                width: 100%;
                height: 100%;
                background: var(--pure-white-900);
                border-radius: 3rem;
                box-shadow: 0 .4rem .4rem 0 clr(neutral-900,1);
                z-index: var(--z-zero);
                @include position(0,null,null,0);
            }
        }
    }
    .site-nav {
        top: var(--header-height);
        left: var(--header-space);
        right: var(--header-space);        
        width: calc(100% - var(--space-6));
        transform: translate(0 , -100%);
        z-index: var(--z-default-neg);
        padding-top: 4rem;
        padding-inline: var(--space-8);
        height: calc(var(--window-inner-height) - var(--header-height));
        overflow-y: auto;
        @include custom-scroll;
        &-left {
            width: calc(40% - var(--space-5));
            padding: 0;
        }
        &-right {
            width: calc(60% - var(--space-5));
            background: transparent;
        }
        &-wrapper {
            flex-direction: row;
            padding-top: var(--space-6);
            gap: var(--space-10);
            margin-bottom: var(--space-5);
        }
        .social-navigation,.official-Partner-module {
            display: none;
        }
        .nav {
            &-link {
                width: 100%;
                padding-top: var(--space-1);
                break-inside: avoid-column;
                &:not(:last-child) {
                    padding-bottom: var(--space-8);
                    &::after {
                        content: unset;
                    }
                }
                &:last-child {
                    display: none;
                }
            }
            &-anchor {
                height: 3rem;
                position: relative;
                padding-left: var(--space-2);
                // &::before {
                //     content: '';
                // }
            }
            &-text {
                font-size: 3rem;
                &::before {
                        content: '';
                }
            }
            &-link-list {
                display: unset;
                column-count: 2;
                gap: var(--space-6) var(--space-2);
                height: auto;
                overflow-y: unset;
            }
        }
        .sub-ul {
            padding-left: var(--space-2);
            padding-top: var(--space-2);
            .sub-nav {
                &-anchor {
                    height: 3rem;
                }
                &-text {
                    color: var(--neutral-700);
                }
                &-text {
                    font-size: 1.8rem;
                }
            }
        }
        button.nav-anchor {
            justify-content: flex-start;
            &::after {
                content: unset;
            }
            &[aria-expanded="true"] {
                & ~ .sub-ul {
                    .sub-nav-anchor {
                        height: 3rem;
                    }
                }
            }
        }
    }
    .event-site-section {
        overflow: unset;
        height: auto;
        padding: 0;
        .event-site {
            &-label {
                display: flex;
            }
            &-list {
                display: flex;
                flex-wrap: wrap;
                gap: var(--space-4);
            }
            &-item {
                width: 15rem;
                flex: unset;
                background: var(--neutral-10);
                padding: var(--space-4);
                border-radius: var(--half-radius);
                &:not(:last-child) {
                    border: 0;
                    margin-bottom: 0;
                }
            }
        }
    }
    .official-Partner-module {
        display: block;
        width: 75rem;
        margin-block: 0 0;
        .layout-wrapper {
            display: flex;
        }
        .waf-head {
            width: 15rem;
            border-radius: var(--half-radius) 0 0 var(--half-radius);
            border-bottom: 0;
            border-right: .1rem solid var(--neutral-100);
        }
        .waf-body {
            width: 57rem;
            border-radius: 0 var(--half-radius) var(--half-radius) 0;
        }
        .event-countdown {
            height: var(--official-Partner-module);
        }
        .card {
            &-title {
                border-bottom: 0;
                margin-bottom: 0;
                padding: 0;
                text-align: left;
                width: auto;
            }
            &-item {
                height: 5rem;
                padding: 0;
                border-radius: 0;
            }
            &-wrap {
                gap: var(--space-4);
                height: 100%;
                padding-inline: var(--space-4);
                @include flex-config(flex,null,center,center);
            }
            &-wrapper {
                border-radius: 0 var(--half-radius) var(--half-radius) 0;
            }
            &-thumbnail {
                padding-bottom: 0;
                flex-wrap: nowrap;
                align-items: center;
                gap: var(--space-3);
                .img-box {
                    width: 8.6rem;
                    height: 3rem;
                }
                img {
                    object-fit: contain;
                    object-position: center;
                }
            }
        }
        .countdown {
            &-list {
                gap: var(--space-4);
            }   
            &-item {
                &:not(:last-child) {
                    &:after {
                        right: -1.2rem;
                    }
                }
            }
        }
    }
    .social-navigation {
        height: var(--social-height);
        @include flex-config(flex,null,null,center);
        @include position(0,var(--space-3),null,null,fixed);
        &::before {
            content: unset;
        }
        .social {
            &-list {
                gap: var(--space-3);
                &-wrap {
                    gap: var(--space-4);
                    padding-block: 0; 
                    @include flex-config(flex,null,null,center);
                }
            }
            &-label {
                margin-bottom: 0;
                color: var(--neutral-10);
            }
            &-anchor {
                color: var(--neutral-10);
            }
            &-link {
                @each $key, $val in $social {
                    &-#{$key} {
                        .social-anchor::before {
                            font-size: 1.2rem;
                        }
                    }
                }
                &-soundcloud,&-telegram {
                    .social-anchor::before {
                        font-size: 1.6rem;
                    }
                }
            }
        }
    }
    .quicklink-section {
        .quicklink-accordion {
            [aria-expanded=true]::before {
                content: "hide";
            }
        }
        .accordion {
            &-title {
                display: block;
            }
            &-btn {
                border-radius: .2rem .2rem 0 0;
                left: var(--container-white-space);
                height: 3rem;
                width: max-content;
                gap: var(--space-2);
            }
            &-body {
                padding-inline: var(--container-white-space);
                gap: var(--space-4);
                @include flex-config(flex,null,null,center);
            }
        }
    }
    .federation-navigation {
        display: flex;
    }
    .site-search {
        margin-left: auto;
        z-index: var(--z-default);
        .search {
            &-input {
                padding-inline: var(--space-8);
            }
            &-label {
                padding-inline: var(--space-5);
            }
            &-wrap {
                z-index: var(--z-default-neg);
            }
        }
    }
    .secondary-header {
        top: 5rem;
        height: 3rem;
        padding-inline: calc(var(--container-white-space) - 15px); 
        &-wrapper {
            gap: var(--space-4);
        }
        .secondary-series-name {
            width: max-content;
        }
        .secondary-series-text {
            font-size: 1.8rem;
        }
        .secondary-nav {
            &-list {
                overflow-x: unset;
            }
            &-anchor {
                padding-inline: var(--space-4);
            }
            &-text {
                font-size: 1.8rem;
            }
        }
    }
    .event-page {
        .site-header {
            &::before {
                height: calc(var(--header-before-height) + 4rem);
            }
        }
    }
    .sticky {
        .site-header {
            &:has(.btn-hamburger[aria-expanded="true"]) {
                top: 0;
                left: 0;
                width: 100%;
                border-radius: 0;
                .primary-header {
                    border-radius: 0;
                }
                .site-nav {
                    left: 0;
                    right: 0;
                    width: 100%;
                }
            }
        }
        .primary-header-wrap {
            border-radius: 0;
            &::before {
                box-shadow: unset;
            }
        }
        .waf-key-events-sticky {
            top: 3.5rem;
        }
        .social-navigation {
            display: none;
        }
    }
    .btn-hamburger[aria-expanded=true] {
        & ~ .site-nav {
            @include flex-config(flex,column,space-between,null);
        }
    }
}